<template>
	<v-menu :close-on-content-click="false" min-width="250px">
		<template v-slot:activator="{ on: menu }">
			<v-tooltip bottom>
				<template v-slot:activator="{ on: tooltip }">
					<v-btn v-if="canSearch && isAccountant" icon v-on="{ ...tooltip, ...menu }">
						<v-icon :color="isFiltering ? 'secondary' : 'primary'" small>fas fa-filter</v-icon>
					</v-btn>
				</template>
				<span v-t="'actions.filter'" />
			</v-tooltip>
		</template>
		<v-list class="py-2" dense lazy max-height="300px">
			<v-subheader v-t="'documents.validation'" />
			<v-radio-group v-model="validationChoice" mandatory>
				<v-list-tile v-for="choice in validationChoices" :key="choice.id">
					<v-list-tile-content>
						<v-radio :key="choice.id" color="primary" :label="choice.text" :value="choice.value"></v-radio>
					</v-list-tile-content>
				</v-list-tile>
			</v-radio-group>
		</v-list>
	</v-menu>
</template>

<script>
import { mapState } from 'vuex'
import DocumentsManagerModuleGuard from '@/mixins/ModulesGuards/Documents/DocumentsManagerModuleGuard'

export default {
	name: 'FileFilterMenu',
	mixins: [DocumentsManagerModuleGuard],
	props: {
		value: {
			type: Object,
			required: false,
			default: null
		}
	},
	data: function () {
		return {
			statuses: {},
			validationStatus: {
				none: 'none',
				validated: 'validated',
				notValidated: 'not_validated'
			}
		}
	},
	computed: {
		...mapState({
			company: state => state.company.selected,
			isAccountant: state => state.auth.isAccountant,
			isFiltering: state => state.documents.isFiltering
		}),
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		},
		canSearch: function () {
			return this.model != null && !this.model?.is_trash
		},
		validationChoices: function () {
			return [
				{ id: 1, text: this.$t('documents.validation_choices.none'), value: this.validationStatus.none },
				{ id: 2, text: this.$t('documents.validation_choices.validated'), value: this.validationStatus.validated },
				{ id: 3, text: this.$t('documents.validation_choices.not_validated'), value: this.validationStatus.notValidated }
			]
		},
		unvalidated: {
			get: function () {
				return this.$store.state.documents.filter.unvalidated
			},
			set: function (value) {
				if (!value && !this.validated) {
					this.validated = true
				}
				this.service.setUnvalidatedFilter(value)
			}
		},
		validated: {
			get: function () {
				return this.$store.state.documents.filter.validated
			},
			set: function (value) {
				if (!value && !this.unvalidated) {
					this.unvalidated = true
				}
				this.service.setValidatedFilter(value)
			}
		},
		validationChoice: {
			get: function () {
				let result
				if (!this.validated && this.unvalidated) {
					result = this.validationChoices.find(choice => choice.value == this.validationStatus.notValidated)?.value
				} else if (this.validated && !this.unvalidated) {
					result = this.validationChoices.find(choice => choice.value == this.validationStatus.validated)?.value
				} else {
					result = this.validationChoices.find(choice => choice.value == this.validationStatus.none)?.value
				}
				return result
			},
			set: function (value) {
				const choice = this.validationChoices.find(choice => choice.value == value)
				if (choice.value == this.validationStatus.notValidated) {
					this.validated = false
					this.unvalidated = true
				} else if (choice.value == this.validationStatus.validated) {
					this.validated = true
					this.unvalidated = false
				} else if (choice.value == this.validationStatus.none) {
					this.validated = true
					this.unvalidated = true
				}
			}
		}
	},
	methods: {
		toggleValidationChoice: function (choice) {
			switch (choice) {
				case this.validationStatus.none:
					this.unvalidated = true
					this.validated = true
					break
				case this.validationStatus.validated:
					this.unvalidated = false
					this.validated = true
					break
				case this.validationStatus.notValidated:
					this.unvalidated = true
					this.validated = false
					break
			}
		}
	}
}
</script>
